var pageglobals = new PageGlobals();

var cookie = Cookie.load(loginGlobals.SPCOOKIEPATH, loginGlobals.SPCOOKIENAME, loginGlobals.SPCOOKIEEXPIREDAYS);
if (cookie == null) {
    cookie = new Cookie(loginGlobals.SPCOOKIEPATH, loginGlobals.SPCOOKIENAME, loginGlobals.SPCOOKIEEXPIREDAYS);
} else {
    // extend the expiration date if there is a user key present
    if (hasValue(cookie.get(loginGlobals.SPCOOKIEKEY))) {
        cookie.save();
    }
}

var controller = {
    initialize: function () {
        var cometRequest = comet.newRequest(chandler, 'INIT', function (cometRequest) {
            pageglobals.set('cusermetadata', cometRequest.getObject('METADATA'));
            growlMessages.showAll(cometRequest);

            // metadata.display() ;
        });
        cometRequest.send();
    },
    proceed: function () {
        // redirect
        var redirect = pageglobals.credirect;
        if (redirect == null || !hasValue(redirect.aString)) {
            gotoURL(loginGlobals.homeURL);
        } else {
            gotoURL(redirect.aString);
        }
    },
    loginKeyUp: function (event) {
        if (isEnterEvent(event)) {
            this.loginClicked();
        }
    },
    loginClicked: function () {
        var validator = new Validator();
        var validateItemOptions = {
            customParent: true,
            parent: document
        };
        validator.addItem(newValidateItem('LOGINUSERNAMEEF', 'Username', validateItemOptions));
        validator.addItem(newValidateItem('LOGINPASSWORDEF', 'Password', validateItemOptions));
        if (validator.isValid()) {
            disableFormItem('loginPB', document);
            var remember = isChecked('rememberMe', document);
            var cometRequest = comet.newRequest(chandler, 'LOGINACTION', function (cometRequest) {
                enableFormItem('loginPB', true, document);
                if (cometRequest.hasUserMessages()) {
                    growlMessages.showAll(cometRequest);
                    focusOn('LOGINUSERNAMEEF', document);
                } else {
                    pageglobals.set('credirect', cometRequest.getObject('REDIRECTHANDLERURL'));
                    globals.setCUser(cometRequest.getObject('USER'));
                    events.trigger(events.USER_JUST_LOGGED_IN);
                    if (remember == false) {
                        cookie.remove();
                    }
                    controller.proceed();
                }
            });
            cometRequest.statusDivId = 'status';
            cometRequest.addFormField('LOGINUSERNAMEEF', false, document);
            cometRequest.addFormField('LOGINPASSWORDEF', false, document);
            if (remember) {
                cometRequest.addParameter('LOGINREMEMBERCB', 'true');
            }
            cometRequest.send();
        }
    },
    registerKeyUp: function (event) {
        if (isEnterEvent(event)) {
            this.registerClicked();
        }
    },
    registerClicked: function () {
        var validator = new Validator();
        var validateItemOptions = {
            customParent: true,
            parent: document
        };
        validator.addItem(newValidateItem('FNAMEEF', 'First Name', validateItemOptions));
        validator.addItem(newValidateItem('LNAMEEF', 'Last Name', validateItemOptions));
        validator.addItem(newValidateItem('REGISTERUSERNAMEEF', 'Username', validateItemOptions));
        validator.addItem(newEmailValidateItem('REGISTEREMAILEF', 'Email', validateItemOptions));

        var cusermetadata = pageglobals.cusermetadata.get('list');
        if (cusermetadata != null) {
            for (var i = 0; i < cusermetadata.length; i++) {
                var acusermetadata = cusermetadata[i];
                if (acusermetadata.get('displayInRegistration') && acusermetadata.get('required')) {
                    validator.addItem(newValidateItem('METAFIELD' + i, acusermetadata.get('name'), validateItemOptions));
                }
            }
        }

        if (validator.isValid()) {
            disableFormItem('registerPB', document);
            var cometRequest = comet.newRequest(loginGlobals.registerHandlerURL, 'REGISTERACTION', function (cometRequest) {
                enableFormItem('registerPB', true, document);
                if (cometRequest.hasUserMessages()) {
                    growlMessages.showAll(cometRequest);
                    focusOn('REGISTERUSERNAMEEF', document);
                } else {
                    globals.setCUser(cometRequest.getObject('USER'));
                    events.trigger(events.USER_JUST_LOGGED_IN);
                    accountEvents.onUserRegistered(globalpageglobals.cuser);
                    controller.proceed();
                }
            });
            cometRequest.statusDivId = 'rstatus';
            cometRequest.addFormField('FNAMEEF', false, document);
            cometRequest.addFormField('LNAMEEF', false, document);
            cometRequest.addFormField('COMPANYEF', false, document);
            cometRequest.addFormField('REGISTERUSERNAMEEF', false, document);
            cometRequest.addFormField('REGISTEREMAILEF', false, document);
            cometRequest.addDropDown('REFERRALDD', false, document);

            if (cusermetadata != null) {
                for (var i = 0; i < cusermetadata.length; i++) {
                    var acusermetadata = cusermetadata[i];
                    if (acusermetadata.get('displayInRegistration')) {
                        var datatype = acusermetadata.get('dataType').id;
                        if (datatype == 'EF' || datatype == 'TA' || datatype == 'DP') {
                            cometRequest.addFormField('METAFIELD' + i, false, document);
                        } else if (datatype == 'CB') {
                            cometRequest.addCheckBox('METAFIELD' + i, false, document);
                        } else if (datatype == 'DD') {
                            cometRequest.addDropDown('METAFIELD' + i, false, document);
                        }
                    }
                }
            }

            cometRequest.send();
        }
    }
};

$(document).ready(function () {
    controller.initialize();
});
